:root {
  --green90: #86fcad;
  --green200Tr: rgba(217, 255, 220, 0.9);
  --green500: #027847;
  --green600: #03654f;
  --green800: #035743;
  --green900: #023e34;
  --black700: #222222;
  --shadow: #cacecece;
  --red500: #d60b00;
  --red200tr: hsla(4, 100%, 90%, 0.9);
  --pink: #d81b60;
  --purple800: #4527a0;
  --purple600: #3949ab;
  --orange600: #ff7700;
  --orange200tr: rgba(255, 228, 204, 0.9);
  --yellow500: #ffeb3b;
  --aqua500: #026e78;
  --blue500: #006aec;
  --blue200Tr: rgba(171, 220, 255, 0.9);
  --white700tr: rgb(238 238 238 / 60%);
  --gray100: #f0f0f0;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* padding: 0px 40px; */
  font-size: large;
  line-height: 1.5;
  width: 100%;
}
h1 {
  font-size: 32px;
  margin: 10px;
}
header a,
divOptions button {
  margin: 8px;
  color: white;
  padding: 4px;
  text-decoration: none;
  border-bottom: 1px solid white;
  height: fit-content;
}
header a.selected {
  border-bottom: none;
}

header a:not(.selected):hover {
  opacity: 0.6;
}
.br8 {
  border-radius: 8px;
}
.v-flex {
  display: flex;
  flex-direction: column;
}

.h-flex {
  display: flex;
  flex-direction: row;
}

.opas:hover {
  opacity: 0.6;
}

.flex-1 {
  flex: 1;
}

.green-bkg {
  background-color: var(--green600);
}
.white-txt {
  color: white;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.fit-c {
  height: fit-content;
}
.center-items {
  align-items: center;
}

.h-flex-end {
  display: flex;
  justify-content: end;
}

.white-bkg {
  background-color: white;
}

.gray-bg {
  background-color: var(--gray100);
}

.h-100 {
  height: 100%;
}

.ov-none {
  overflow: hidden;
}

.ov-auto {
  overflow: auto;
}

.green-field {
  background-color: var(
    --green600
  ); /* For browsers that do not support gradients */
  background: linear-gradient(
    to bottom,
    var(--green600),
    var(--green600) 50%,
    var(--green800) 50%,
    var(--green800)
  );
  background-size: 100%;
  background: url("../images/field.svg") center top no-repeat,
    linear-gradient(
      to bottom,
      var(--green600),
      /* var(--green600) 50%, */ /* var(--green800) 50%, */ var(--green800)
    );
  background-size: contain;
}

.txt-green-100 {
  color: #02210e;
}

.pad-8 {
  padding: 8px;
}
.pad-4 {
  padding: 4px;
}
main {
  border-radius: 8px;
}

.vh-flex-center {
  align-items: center;
  justify-content: center;
}
.j-center {
  justify-content: center;
}
.self-center {
  align-self: center;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 10px;
  background-color: rgb(209, 247, 212);
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #9fbca2;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #669c7f;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #669c7f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #456a56;
  background: linear-gradient(0deg, rgb(47, 84, 46) 50%, rgb(40, 78, 54) 50%);
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: #456a56;
  background: linear-gradient(0deg, rgb(47, 84, 46) 50%, rgb(40, 78, 54) 50%);
}

footer {
  margin: 8px;
}

.btn-trans {
  background-color: transparent;
}

.wRes80 {
  width: 80%;
}
.wRes70 {
  width: 70%;
}
button {
  outline: none;
  border: none;
}
.wh-48 {
  width: 48px;
  height: 48px;
}
.f-16 {
  font-size: 16px;
}

.f-24,
.f-30-res {
  font-size: 24px;
}
.f-48 {
  font-size: 48px;
}
.show-block {
  display: block;
}

.hide-block {
  display: none;
}
.hide {
  display: none;
}
.ml30 {
  margin-left: 30px;
}
.pos-fixed {
  position: fixed;
  top: 0;
  right: 0;
}
.no-deco a {
  text-decoration: none;
  border: none;
}
.gray-bkg,
.gray-bkg a {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #37443d;
  color: white;
}
.gray-bkg a:hover {
  background-color: #9fbca2;
}
.mt-8 {
  margin-top: 8px;
}
.m8 {
  margin: 8px;
}
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
  right: 0;
  top: 0;
}
.abs-center {
  position: absolute;
  inset: 0;
  margin: auto;
}
.v-flex-res {
  display: flex;
  flex-direction: column;
}
.h-flex-res {
  display: flex;
  flex-direction: row;
}
.circle-white {
  background-color: white;
  border-radius: 50%;
  image-orientation: from-image;
  padding: 2px;
}
.circle-green {
  background-color: rgba(0, 138, 80, 0.509);
  border-radius: 50%;
  image-orientation: from-image;
  padding: 2px;
}
.btn-white-green-shw {
  background-color: white;
  box-shadow: 0 3px 3px rgba(0, 91, 36, 0.3);
  border-radius: 50px;
  padding: 8px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  min-width: 90px;
}

.btn-circle-green-shw {
  background-color: var(--green200Tr);
  box-shadow: 0 3px 3px rgba(97, 97, 97, 0.3);
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  image-orientation: from-image;
  padding: 2px;
  width: 40px;
  height: 40px;
}
.btn-circle-white-green-shw {
  background-color: white;
  box-shadow: 0 3px 3px rgba(0, 91, 36, 0.3);
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  image-orientation: from-image;
  padding: 2px;
  width: 40px;
  height: 40px;
}
.btn-green-shw {
  background-color: var(--green600);
  box-shadow: 0 3px 3px rgba(72, 72, 72, 0.3);
  border-radius: 50px;
  padding: 8px;
  color: white;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  min-width: 90px;
}

.btn-gl-gray-shw {
  background-color: var(--gray100);
  box-shadow: 0 3px 3px rgba(72, 72, 72, 0.3);
  border-radius: 50px;
  padding: 8px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  min-width: 90px;
}
.f-12 {
  font-size: 12px;
}
.btn-blue-shw {
  background-color: var(--blue500);
  box-shadow: 0 3px 3px rgba(72, 72, 72, 0.3);
  border-radius: 50px;
  padding: 8px;
  color: white;
  text-decoration: none;
  text-align: center;
  min-width: 90px;
  cursor: pointer;
}

.btn-red-shw {
  background-color: var(--red500);
  box-shadow: 0 3px 3px rgba(72, 72, 72, 0.3);
  border-radius: 50px;
  padding: 8px;
  color: white;
  text-decoration: none;
  text-align: center;
  min-width: 90px;
  cursor: pointer;
}

.soon {
  background-color: rgba(4, 91, 51, 0.619);
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pos-abs-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
.br-8 {
  border-radius: 8px;
}

.green-txt {
  color: var(--green600);
}
.blue-txt {
  color: var(--blue500);
}
.bold-txt {
  font-weight: bold;
}

.drop-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: black;
  text-decoration: none;
  display: block;
  text-align: left;
}
.drop-down {
  background: var(--green800);
  position: absolute;
}
.drop-content ul li {
  cursor: pointer;
  display: flex;
  width: 100%;
}
.drop-content label {
  cursor: pointer;
  padding: 12px 4px;
  width: 100%;
}
.drop-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgb(144, 231, 255);
  z-index: 1;
  min-width: 120px;
}
.drop-content li:hover:not(.active) {
  background-color: #ececec;
}
.drop:hover .drop-content {
  display: block;
}
.normal {
  font-weight: normal;
}
.drop-content .active {
  background-color: #d8f3ff;
}
.btn-border-white {
  border: none;
  border-radius: 10px;
  box-shadow: 0 3px 3px rgb(0 0 0/30%);
  color: white;
  cursor: pointer;
  font-weight: 600;
  outline: none;
  padding: 8px;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid white;
}

.btn-white-gray {
  background-color: white;
  box-shadow: 0 3px 3px rgba(72, 72, 72, 0.3);
  border-radius: 50px;
  padding: 8px;
  color: var(--black700);
  text-decoration: none;
  text-align: center;
  min-width: 90px;
  cursor: pointer;
  border: 1px solid gainsboro;
}
.h-fit {
  height: fit-content;
}
.w-fit {
  width: fit-content;
}
.w-fill {
  width: -webkit-fill-available;
}
.self-end {
  align-self: flex-end;
}
.mr8 {
  margin-right: 8px;
}

.video {
  width: 50%;
  height: 360px;
  background-color: black;
}

.error,
.live-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w100 {
  width: 100%;
}

.point {
  cursor: pointer;
}
.w50p {
  width: 50%;
}
.w80p {
  width: 80%;
}
.h360 {
  height: 360px;
}
.f1-res {
  flex: unset;
}
.mh8 {
  margin-left: 8px;
  margin-right: 8px;
}

.empty {
  background-color: rgba(0, 33, 21, 0.68);
  color: white;
  border-radius: 50px;
  padding: 4px 8px;
  font-size: medium;
}

.btn-circle-white-br-gray {
  background-color: white;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  image-orientation: from-image;
  padding: 2px;
  border: 1px solid rgb(173, 173, 173);
}
.btn-circle-white-br-gray:hover {
  border: 1px solid rgb(136, 136, 136);
}

.btn-circle-trans {
  background-color: transparent;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  image-orientation: from-image;
  padding: 2px;
}
.btn-circle-trans:hover {
  background-color: rgba(128, 128, 128, 0.589);
}

.t-center {
  text-align: center;
}
.wh-16 {
  width: 16px;
  height: 16px;
}
.wh-24 {
  width: 24px;
  height: 24px;
}
.pad-2 {
  padding: 2px;
}
.white-txt-bg {
  border-radius: 0px 12px 12px 12px;
  padding: 8px;
  background-color: var(--white700tr);
  color: var(--black700);
}
.f-12 {
  font-size: 12px;
}

.img-contain {
  object-fit: contain;
}
.img-cover {
  object-fit: cover;
}

.mt-auto {
  margin-top: auto;
}
.mb-8 {
  margin-bottom: 8px;
}
.j-end {
  justify-content: end;
}

.modal {
  display: none; /* Hidden by default */
  /* Stay in place :was fixed*/
  position: absolute;
  z-index: 4; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  /*background-color: rgba(0,0,0,0.4);*/ /*origina/*/
  background-color: rgba(0, 0, 0, 0.747); /* Black w/ opacity */
  flex-direction: column;
  justify-content: center;
}
.modal-content {
  padding: 8px;
  align-items: center;
}

.l-green-bkg {
  background: var(--green500);
}

.l-green-bkg-shw {
  background: var(--green500);
  box-shadow: 2px 2px 4px 3px rgba(72, 72, 72, 0.3);
}

.green-bkg-shw {
  background: var(--green600);
  box-shadow: 2px 2px 4px 3px rgba(72, 72, 72, 0.3);
}
.flex-1-res-2 {
  flex: 1;
}
.w-min {
  width: min-content;
}
#toast {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-radius: 8px;
  color: var(--black700);
  width: fit-content;
  margin: auto;
  padding: 8px;
  visibility: hidden;
  min-width: 150px;
  z-index: 20;
  font-weight: bold;
}

#toast.success {
  background-color: var(--green200Tr);
}

#toast.warning {
  background-color: var(--orange200tr);
}
#toast.error {
  background-color: var(--red200tr);
}
#toast.info {
  background-color: var(--blue200Tr);
}

.ml-8 {
  margin-left: 8px;
}
.f-14 {
  font-size: 14px;
}
#toast.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
.self-top {
  align-self: flex-start;
}
.space-even {
  justify-content: space-evenly;
}

.left-rnd-white-bkg {
  background-color: white;
  box-shadow: 0 3px 8px var(--shadow);
  border-radius: 50px 0px 0px 50px;
  padding: 8px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  min-width: 90px;
}
.white-bkg-shw {
  background-color: white;
  box-shadow: 0 3px 8px var(--shadow);
  border-radius: 8px;
  padding: 8px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  min-width: 90px;
  color: var(--black700);
}

.t-start {
  text-align: start;
}

.red-txt {
  color: var(--red500);
}

.grid-2 {
  display: grid;
  grid-template-columns: auto auto;
}
.overlap-top {
  position: relative;
  /* margin-left: auto; */
  /* margin-right: auto; */
  z-index: 1;
  /* background-color: white; */
}
.overlap-btm {
  position: absolute;
  top: 24px;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  background: white;
  padding: 30px 8px 8px 8px;
}
.bkg-overlap {
  position: absolute;
  top: 24px;
  bottom: 0px;
  left: 0;
  right: 0;
}
.rg64-res {
  row-gap: 64px;
}
#inMsg {
  border-radius: 8px;
  border: 1px solid gray;
}
.in-msg {
  border-radius: 0 8px 8px 8px;
  background-color: white;
  box-shadow: 0 3px 8px var(--gray100);
}
.top-left {
  left: 0;
  top: 0;
}
.wh30 {
  height: 30px;
  width: 30px;
}
.pad0 {
  padding: 0px;
}

.green-90 {
  background-color: var(--green90);
}
.mw-50-res {
  min-width: 50%;
}

.one-line {
  overflow: hidden;
  line-break: anywhere;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
input[type="radio"].no-rad {
  display: none;
}
.no-select {
  user-select: none;
}
.no-space {
  margin: 0px;
  padding: 0px;
  border: none;
}
#aPlayStore img {
  height: 48px;
}
@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
@media screen and (max-width: 800px) {
  body {
    font-size: medium;
  }
  .wRes70,
  .wRes80 {
    width: 90%;
  }
  .f-24 {
    font-size: 34px;
  }
  .f-30-res {
    font-size: 20px;
  }
  .f-48 {
    font-size: 58px;
  }

  .v-flex-res {
    display: flex;
    flex-direction: row;
  }
  .h-flex-res {
    display: flex;
    flex-direction: column;
  }

  .video {
    width: 100%;
    height: 200px;
    background-color: black;
    flex: unset;
  }
  .w50p,
  .w80p {
    width: auto;
  }
  .h360 {
    flex: 1;
  }
  .f1-res {
    flex: 1;
    overflow: hidden;
  }
  .abs-res {
    position: absolute;
    width: 100%;
    bottom: 0;
    color: var(--red500);
    /* overflow: hidden; */
  }
  .flex-1-res-2 {
    flex: 2;
  }

  .rg64-res {
    row-gap: 8px;
  }
  .mw-50-res {
    min-width: 100%;
  }
}
