:root {
  --green90: #86fcad;
  --green200Tr: #d9ffdce6;
  --green500: #027847;
  --green600: #03654f;
  --green800: #035743;
  --green900: #023e34;
  --black700: #222;
  --shadow: #cacecece;
  --red500: #d60b00;
  --red200tr: #ffcfcce6;
  --pink: #d81b60;
  --purple800: #4527a0;
  --purple600: #3949ab;
  --orange600: #f70;
  --orange200tr: #ffe4cce6;
  --yellow500: #ffeb3b;
  --aqua500: #026e78;
  --blue500: #006aec;
  --blue200Tr: #abdcffe6;
  --white700tr: #eee9;
  --gray100: #f0f0f0;
}

body {
  width: 100%;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: large;
  line-height: 1.5;
}

h1 {
  margin: 10px;
  font-size: 32px;
}

header a, divOptions button {
  color: #fff;
  border-bottom: 1px solid #fff;
  height: -moz-fit-content;
  height: fit-content;
  margin: 8px;
  padding: 4px;
  text-decoration: none;
}

header a.selected {
  border-bottom: none;
}

header a:not(.selected):hover {
  opacity: .6;
}

.br8 {
  border-radius: 8px;
}

.v-flex {
  flex-direction: column;
  display: flex;
}

.h-flex {
  flex-direction: row;
  display: flex;
}

.opas:hover {
  opacity: .6;
}

.flex-1 {
  flex: 1;
}

.green-bkg {
  background-color: var(--green600);
}

.white-txt {
  color: #fff;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.fit-c {
  height: -moz-fit-content;
  height: fit-content;
}

.center-items {
  align-items: center;
}

.h-flex-end {
  justify-content: end;
  display: flex;
}

.white-bkg {
  background-color: #fff;
}

.gray-bg {
  background-color: var(--gray100);
}

.h-100 {
  height: 100%;
}

.ov-none {
  overflow: hidden;
}

.ov-auto {
  overflow: auto;
}

.green-field {
  background-color: var(--green600);
  background: linear-gradient(to bottom, var(--green600), var(--green600) 50%, var(--green800) 50%, var(--green800));
  background-size: 100%;
  background: url("field.f9ab39fc.svg") center top no-repeat, linear-gradient(to bottom, var(--green600), var(--green800));
  background-size: contain;
}

.txt-green-100 {
  color: #02210e;
}

.pad-8 {
  padding: 8px;
}

.pad-4 {
  padding: 4px;
}

main {
  border-radius: 8px;
}

.vh-flex-center {
  justify-content: center;
  align-items: center;
}

.j-center {
  justify-content: center;
}

.self-center {
  align-self: center;
}

::-webkit-scrollbar {
  background-color: #d1f7d4;
  border-radius: 10px;
  width: 12px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #9fbca2;
}

::-webkit-scrollbar-thumb {
  background: #669c7f;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #669c7f;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(0deg, #2f542e 50%, #284e36 50%);
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: linear-gradient(0deg, #2f542e 50%, #284e36 50%);
}

footer {
  margin: 8px;
}

.btn-trans {
  background-color: #0000;
}

.wRes80 {
  width: 80%;
}

.wRes70 {
  width: 70%;
}

button {
  border: none;
  outline: none;
}

.wh-48 {
  width: 48px;
  height: 48px;
}

.f-16 {
  font-size: 16px;
}

.f-24, .f-30-res {
  font-size: 24px;
}

.f-48 {
  font-size: 48px;
}

.show-block {
  display: block;
}

.hide-block, .hide {
  display: none;
}

.ml30 {
  margin-left: 30px;
}

.pos-fixed {
  position: fixed;
  top: 0;
  right: 0;
}

.no-deco a {
  border: none;
  text-decoration: none;
}

.gray-bkg, .gray-bkg a {
  z-index: 1;
  color: #fff;
  background-color: #37443d;
  box-shadow: 0 8px 16px #0003;
}

.gray-bkg a:hover {
  background-color: #9fbca2;
}

.mt-8 {
  margin-top: 8px;
}

.m8 {
  margin: 8px;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
  top: 0;
  right: 0;
}

.abs-center {
  margin: auto;
  position: absolute;
  inset: 0;
}

.v-flex-res {
  flex-direction: column;
  display: flex;
}

.h-flex-res {
  flex-direction: row;
  display: flex;
}

.circle-white {
  image-orientation: from-image;
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
}

.circle-green {
  image-orientation: from-image;
  background-color: #008a5082;
  border-radius: 50%;
  padding: 2px;
}

.btn-white-green-shw {
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  min-width: 90px;
  padding: 8px;
  text-decoration: none;
  box-shadow: 0 3px 3px #005b244d;
}

.btn-circle-green-shw {
  background-color: var(--green200Tr);
  text-align: center;
  cursor: pointer;
  image-orientation: from-image;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 2px;
  text-decoration: none;
  box-shadow: 0 3px 3px #6161614d;
}

.btn-circle-white-green-shw {
  text-align: center;
  cursor: pointer;
  image-orientation: from-image;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 2px;
  text-decoration: none;
  box-shadow: 0 3px 3px #005b244d;
}

.btn-green-shw {
  background-color: var(--green600);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  min-width: 90px;
  padding: 8px;
  text-decoration: none;
  box-shadow: 0 3px 3px #4848484d;
}

.btn-gl-gray-shw {
  background-color: var(--gray100);
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  min-width: 90px;
  padding: 8px;
  text-decoration: none;
  box-shadow: 0 3px 3px #4848484d;
}

.btn-blue-shw {
  background-color: var(--blue500);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  min-width: 90px;
  padding: 8px;
  text-decoration: none;
  box-shadow: 0 3px 3px #4848484d;
}

.btn-red-shw {
  background-color: var(--red500);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  min-width: 90px;
  padding: 8px;
  text-decoration: none;
  box-shadow: 0 3px 3px #4848484d;
}

.soon {
  text-align: center;
  background-color: #045b339e;
  place-content: center;
  align-items: center;
  display: flex;
}

.pos-abs-fill {
  z-index: 5;
  position: absolute;
  inset: 0;
}

.br-8 {
  border-radius: 8px;
}

.green-txt {
  color: var(--green600);
}

.blue-txt {
  color: var(--blue500);
}

.bold-txt {
  font-weight: bold;
}

.drop-content ul {
  color: #000;
  text-align: left;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  display: block;
}

.drop-down {
  background: var(--green800);
  position: absolute;
}

.drop-content ul li {
  cursor: pointer;
  width: 100%;
  display: flex;
}

.drop-content label {
  cursor: pointer;
  width: 100%;
  padding: 12px 4px;
}

.drop-content {
  z-index: 1;
  background-color: #f9f9f9;
  min-width: 120px;
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px #90e7ff;
}

.drop-content li:hover:not(.active) {
  background-color: #ececec;
}

.drop:hover .drop-content {
  display: block;
}

.normal {
  font-weight: normal;
}

.drop-content .active {
  background-color: #d8f3ff;
}

.btn-border-white {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 3px 3px #0000004d;
}

.btn-white-gray {
  color: var(--black700);
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 50px;
  min-width: 90px;
  padding: 8px;
  text-decoration: none;
  box-shadow: 0 3px 3px #4848484d;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-fill {
  width: -webkit-fill-available;
}

.self-end {
  align-self: flex-end;
}

.mr8 {
  margin-right: 8px;
}

.video {
  background-color: #000;
  width: 50%;
  height: 360px;
}

.error, .live-loader {
  z-index: 5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.w100 {
  width: 100%;
}

.point {
  cursor: pointer;
}

.w50p {
  width: 50%;
}

.w80p {
  width: 80%;
}

.h360 {
  height: 360px;
}

.f1-res {
  flex: unset;
}

.mh8 {
  margin-left: 8px;
  margin-right: 8px;
}

.empty {
  color: #fff;
  background-color: #002115ad;
  border-radius: 50px;
  padding: 4px 8px;
  font-size: medium;
}

.btn-circle-white-br-gray {
  text-align: center;
  cursor: pointer;
  image-orientation: from-image;
  background-color: #fff;
  border: 1px solid #adadad;
  border-radius: 50%;
  padding: 2px;
  text-decoration: none;
}

.btn-circle-white-br-gray:hover {
  border: 1px solid #888;
}

.btn-circle-trans {
  text-align: center;
  cursor: pointer;
  image-orientation: from-image;
  background-color: #0000;
  border-radius: 50%;
  padding: 2px;
  text-decoration: none;
}

.btn-circle-trans:hover {
  background-color: #80808096;
}

.t-center {
  text-align: center;
}

.wh-16 {
  width: 16px;
  height: 16px;
}

.wh-24 {
  width: 24px;
  height: 24px;
}

.pad-2 {
  padding: 2px;
}

.white-txt-bg {
  background-color: var(--white700tr);
  color: var(--black700);
  border-radius: 0 12px 12px;
  padding: 8px;
}

.f-12 {
  font-size: 12px;
}

.img-contain {
  object-fit: contain;
}

.img-cover {
  object-fit: cover;
}

.mt-auto {
  margin-top: auto;
}

.mb-8 {
  margin-bottom: 8px;
}

.j-end {
  justify-content: end;
}

.modal {
  z-index: 4;
  background-color: #000000be;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-content {
  align-items: center;
  padding: 8px;
}

.l-green-bkg {
  background: var(--green500);
}

.l-green-bkg-shw {
  background: var(--green500);
  box-shadow: 2px 2px 4px 3px #4848484d;
}

.green-bkg-shw {
  background: var(--green600);
  box-shadow: 2px 2px 4px 3px #4848484d;
}

.flex-1-res-2 {
  flex: 1;
}

.w-min {
  width: min-content;
}

#toast {
  color: var(--black700);
  visibility: hidden;
  z-index: 20;
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 150px;
  margin: auto;
  padding: 8px;
  font-weight: bold;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

#toast.success {
  background-color: var(--green200Tr);
}

#toast.warning {
  background-color: var(--orange200tr);
}

#toast.error {
  background-color: var(--red200tr);
}

#toast.info {
  background-color: var(--blue200Tr);
}

.ml-8 {
  margin-left: 8px;
}

.f-14 {
  font-size: 14px;
}

#toast.show {
  visibility: visible;
  animation: .5s fadein, .5s 2.5s fadeout;
}

@keyframes fadein {
  from {
    opacity: 0;
    bottom: 0;
  }

  to {
    opacity: 1;
    bottom: 30px;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    bottom: 30px;
  }

  to {
    opacity: 0;
    bottom: 0;
  }
}

.self-top {
  align-self: flex-start;
}

.space-even {
  justify-content: space-evenly;
}

.left-rnd-white-bkg {
  box-shadow: 0 3px 8px var(--shadow);
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px 0 0 50px;
  min-width: 90px;
  padding: 8px;
  text-decoration: none;
}

.white-bkg-shw {
  box-shadow: 0 3px 8px var(--shadow);
  text-align: center;
  cursor: pointer;
  color: var(--black700);
  background-color: #fff;
  border-radius: 8px;
  min-width: 90px;
  padding: 8px;
  text-decoration: none;
}

.t-start {
  text-align: start;
}

.red-txt {
  color: var(--red500);
}

.grid-2 {
  grid-template-columns: auto auto;
  display: grid;
}

.overlap-top {
  z-index: 1;
  position: relative;
}

.overlap-btm {
  background: #fff;
  padding: 30px 8px 8px;
  position: absolute;
  top: 24px;
}

.bkg-overlap {
  position: absolute;
  inset: 24px 0 0;
}

.rg64-res {
  row-gap: 64px;
}

#inMsg {
  border: 1px solid gray;
  border-radius: 8px;
}

.in-msg {
  box-shadow: 0 3px 8px var(--gray100);
  background-color: #fff;
  border-radius: 0 8px 8px;
}

.top-left {
  top: 0;
  left: 0;
}

.wh30 {
  width: 30px;
  height: 30px;
}

.pad0 {
  padding: 0;
}

.green-90 {
  background-color: var(--green90);
}

.mw-50-res {
  min-width: 50%;
}

.one-line {
  line-break: anywhere;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

input[type="radio"].no-rad {
  display: none;
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
}

.no-space {
  border: none;
  margin: 0;
  padding: 0;
}

#aPlayStore img {
  height: 48px;
}

@media screen and (width <= 800px) {
  body {
    font-size: medium;
  }

  .wRes70, .wRes80 {
    width: 90%;
  }

  .f-24 {
    font-size: 34px;
  }

  .f-30-res {
    font-size: 20px;
  }

  .f-48 {
    font-size: 58px;
  }

  .v-flex-res {
    flex-direction: row;
    display: flex;
  }

  .h-flex-res {
    flex-direction: column;
    display: flex;
  }

  .video {
    flex: unset;
    background-color: #000;
    width: 100%;
    height: 200px;
  }

  .w50p, .w80p {
    width: auto;
  }

  .h360 {
    flex: 1;
  }

  .f1-res {
    flex: 1;
    overflow: hidden;
  }

  .abs-res {
    color: var(--red500);
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .flex-1-res-2 {
    flex: 2;
  }

  .rg64-res {
    row-gap: 8px;
  }

  .mw-50-res {
    min-width: 100%;
  }
}

/*# sourceMappingURL=index.2f11a73d.css.map */
